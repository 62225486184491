import { Route, Routes } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import Home from "./pages/Home";
import LogIn from "./pages/Login";

import BarcodeReader from './components/BarcodeReader';

import Sale from "./pages/Sale";
import ReturnSaleProduct from "./pages/sale/ReturnSaleProduct";

import AllCustomerReturn from "./pages/customerReturn/AllCustomerReturn";
import ViewSaleReturn from "./pages/customerReturn/ViewSaleReturn";
import InvoiceSaleReturn from "./pages/customerReturn/InvoiceSaleReturn";

import Purchase from "./pages/Purchase";
import AllPurchaseReturn from "./pages/supplierReturn/AllPurchaseReturn";
import ReturnPurchaseProduct from "./pages/purchase/ReturnPurchaseProduct";
import ViewSupplierReturn from "./pages/supplierReturn/ViewSupplierReturn";
import InvoicePurchaseReturn from "./pages/supplierReturn/InvoicePurchaseReturn";


import IsLogIn from "./components/IsLogIn";
import IsLogOut from "./components/IsLogOut";

import AllUser from "./pages/user/AllUser";
import AddUser from "./pages/user/AddUser";

import Profile from "./pages/Profile";
import Setting from "./pages/Setting";

import AllCategory from "./pages/category/AllCategory";
import AddCategory from "./pages/category/AddCategory";
import EditCategory from "./pages/category/EditCategory";

import AllProduct from "./pages/product/AllProduct";
import AddProduct from "./pages/product/AddProduct";
import EditProduct from "./pages/product/EditProduct";
import ProductBarcode from "./pages/product/ProductBarcode";

import AllExpenseCategory from "./pages/expenseCategory/AllExpenseCategory";
import AddExpenseCategory from "./pages/expenseCategory/AddExpenseCategory";

import AllExpense from "./pages/expense/AllExpense";
import AddExpense from "./pages/expense/AddExpense";

import AddAccessories from "./pages/accessories/AddAccessories";
import EditAccessories from "./pages/accessories/EditAccessories";
import AllAccessories from "./pages/accessories/AllAccessories";


import AllCustomer from "./pages/customer/AllCustomer";
import AddCustomer from "./pages/customer/AddCustomer";
import EditCustomer from "./pages/customer/EditCustomer";
import CustomerDetails from "./pages/customer/CustomerDetails";

import AllSupplier from "./pages/supplier/AllSupplier";
import AddSupplier from "./pages/supplier/AddSupplier";
import EditSupplier from "./pages/supplier/EditSupplier";
import SupplierDetails from "./pages/supplier/SupplierDetails";


// import AllWaiter from "./pages/waiter/AllWaiter";
// import AddWaiter from "./pages/waiter/AddWaiter";
// import EditWaiter from "./pages/waiter/EditWaiter";

// import AllTable from "./pages/table/AllTable";
// import AddTable from "./pages/table/AddTable";
// import EditTable from "./pages/table/EditTable";

// import AddCoupon from "./pages/coupon/AddCoupon";
// import AllCoupon from "./pages/coupon/AllCoupon";

// import AddReword from "./pages/reword/AddReword";
// import AllReword from "./pages/reword/AllReword";

// import Counter from "./pages/order/Counter";

// import AllOrder from "./pages/order/AllOrder";
// import NewOrder from "./pages/order/NewOrder";
// import CompleteOrder from "./pages/order/CompleteOrder";
// import CancelOrder from "./pages/order/CancelOrder";

import TodaySale from "./pages/sale/TodaySale";
import ThisWeekSale from "./pages/sale/ThisWeekSale";
import ThisMonthSale from "./pages/sale/ThisMonthSale";
import ThisYearSale from "./pages/sale/ThisYearSale";
import AllSale  from "./pages/sale/AllSale";

import ViewSale from "./pages/sale/ViewSale";
import Invoice from "./pages/sale/Invoice";
import InvoiceDue from "./pages/sale/InvoiceDue";

import Quotation from "./pages/Quotation";
import EditQuotation from "./pages/quotation/EditQuotation";
import AllQuotation from "./pages/quotation/AllQuotation";
import QuotationInvoice from "./pages/quotation/QuotationInvoice";
import SaleQuotations from "./pages/quotation/SaleQuotations";


import PayDue from "./pages/sale/PayDue";
import AllSaleDue from "./pages/sale/AllSaleDue";


import TodayPurchase from "./pages/purchase/TodayPurchase";
import ThisWeekPurchase from "./pages/purchase/ThisWeekPurchase";
import ThisMonthPurchase from "./pages/purchase/ThisMonthPurchase";
import ThisYearPurchase from "./pages/purchase/ThisYearPurchase";
import AllPurchase from "./pages/purchase/AllPurchase";

import ViewPurchase from "./pages/purchase/ViewPurchase";
import InvoiceP  from "./pages/purchase/Invoice";
import InvoiceDueP  from "./pages/purchase/InvoiceDue";

import PayDueP from "./pages/purchase/PayDue";
import AllPurchaseDue from "./pages/purchase/AllPurchaseDue";


import TodayExpense from "./pages/expenseReport/TodayExpense";
import ThisWeekExpense from "./pages/expenseReport/ThisWeekExpense";
import ThisMonthExpense from "./pages/expenseReport/ThisMonthExpense";
import ThisYearExpense from "./pages/expenseReport/ThisYearExpense";


// import UpdateOrder from "./pages/order/UpdateOrder";
// import UpdatePos from "./pages/order/UpdatePos";



import Layout from "./components/Layout";
import { NavLInkHome } from "./components/NavLink";
import Dashboard from "./pages/Dashboard";
import CheckUser from "./components/CheckUser";

function App() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<IsLogOut />}>
          <Route path="logIn" element={<LogIn />} />
        </Route>

        <Route path="/" element={<IsLogIn />}>
          <Route path="/" element={<Home />} />
          <Route path="sale/invoice/:id" element={<Invoice />} />
          <Route path="sale/due/invoice/:id" element={<InvoiceDue />} />

          <Route path="quotation/invoice/:id" element={<QuotationInvoice />} />
          <Route path="customer/return/invoice/:id" element={<InvoiceSaleReturn />} />
          <Route path="supplier/return/invoice/:id" element={<InvoicePurchaseReturn />} />

          <Route path="product/barcode" element={<ProductBarcode />} />
          <Route path="/barcode" element={<BarcodeReader />} />


          <Route path="purchase/invoice/:id" element={<InvoiceP />} />
          <Route path="purchase/due/invoice/:id" element={<InvoiceDueP />} />

          <Route path="/" element={<CheckUser status="both" />}>
            <Route path="/*" element={<Layout nav={<NavLInkHome />} />}>

              <Route path="profile" element={<Profile />} />
              <Route path="setting" element={<Setting />} />

              <Route path="quotation/create" element={<Quotation />} />
              <Route path="quotation/sale/:id" element={<SaleQuotations />} />

              <Route path="sale" element={<Sale />} />
              <Route path="return/sale/:id" element={<ReturnSaleProduct />} />
              <Route path="customer/return" element={<AllCustomerReturn />} />
              <Route path="customer/return/view/:id" element={<ViewSaleReturn />} />

              <Route path="quotations" element={<AllQuotation />} />
              <Route path="quotation/:id" element={<EditQuotation />} />

              <Route path="purchase" element={<Purchase />} />
              <Route path="return/purchase/:id" element={<ReturnPurchaseProduct />} />
              <Route path="supplier/return" element={<AllPurchaseReturn />} />
              <Route path="supplier/return/view/:id" element={<ViewSupplierReturn />} />



              <Route path="customer" element={<AllCustomer />} />
              <Route path="AddCustomer" element={<AddCustomer />} />
              <Route path="editCustomer/:id" element={<EditCustomer />} />
              <Route path="customer/details/:id" element={<CustomerDetails />} />

              <Route path="supplier" element={<AllSupplier />} />
              <Route path="AddSupplier" element={<AddSupplier />} />
              <Route path="editSupplier/:id" element={<EditSupplier />} />
              <Route path="supplier/details/:id" element={<SupplierDetails />} />
              

              <Route path="todaySale" element={<TodaySale />} />
              <Route path="thisWeekSale" element={<ThisWeekSale />} />
              <Route path="thisMonthSale" element={<ThisMonthSale />} />
              <Route path="thisYearSale" element={<ThisYearSale />} />
              <Route path="allSale" element={<AllSale />} />

              <Route path="sale/view/:id" element={<ViewSale />} />
              <Route path="sale/due/:id" element={<PayDue />} />
              <Route path="sale/allReceivedDue" element={<AllSaleDue />} />

              <Route path="todayPurchase" element={<TodayPurchase />} />
              <Route path="thisWeekPurchase" element={<ThisWeekPurchase />} />
              <Route path="thisMonthPurchase" element={<ThisMonthPurchase />} />
              <Route path="thisYearPurchase" element={<ThisYearPurchase />} />
              <Route path="allPurchase" element={<AllPurchase />} />

              <Route path="purchase/view/:id" element={<ViewPurchase />} />
              <Route path="purchase/due/:id" element={<PayDueP />} />
              <Route path="purchase/allPaidDue" element={<AllPurchaseDue />} />


              {/* <Route path="counter" element={<Counter />} /> */}

              {/* <Route path="order/:id" element={<UpdateOrder />} /> */}
              {/* <Route path="pos/update/:id" element={<UpdatePos />} /> */}


              <Route path="AddCustomer" element={<AddCustomer />} />
            </Route>
          </Route>

          <Route path="/*" element={<CheckUser status="admin" />}>
            <Route path="/*" element={<Layout nav={<NavLInkHome />} />}>
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="user" element={<AllUser />} />
              <Route path="addUser" element={<AddUser />} />

              <Route path="category" element={<AllCategory />} />
              <Route path="AddCategory" element={<AddCategory />} />
              <Route path="editCategory/:id" element={<EditCategory />} />

              <Route path="product" element={<AllProduct />} />
              <Route path="AddProduct" element={<AddProduct />} />
              <Route path="editProduct/:id" element={<EditProduct />} />

              <Route path="expenseCategory" element={<AllExpenseCategory />} />
              <Route path="AddExpenseCategory" element={<AddExpenseCategory />} />
              {/* <Route path="editExpenseCategory/:id" element={<EditExpenseCategory />} /> */}

              <Route path="accessories" element={<AllAccessories />} />
              <Route path="addAccessories" element={<AddAccessories />} />
              <Route path="editAccessories/:id" element={<EditAccessories />} />

              <Route path="expense" element={<AllExpense />} />
              <Route path="addExpense" element={<AddExpense />} />

              <Route path="todayExpense" element={<TodayExpense />} />
              <Route path="thisWeekExpense" element={<ThisWeekExpense />} />
              <Route path="thisMonthExpense" element={<ThisMonthExpense />} />
              <Route path="thisYearExpense" element={<ThisYearExpense />} />

              {/* <Route path="waiter" element={<AllWaiter />} />
              <Route path="AddWaiter" element={<AddWaiter />} />
              <Route path="editWaiter/:id" element={<EditWaiter />} />

              <Route path="table" element={<AllTable />} />
              <Route path="AddTable" element={<AddTable />} />
              <Route path="editTable/:id" element={<EditTable />} />

              <Route path="coupon" element={<AllCoupon />} />
              <Route path="AddCoupon" element={<AddCoupon />} />

              <Route path="reward" element={<AllReword />} />
              <Route path="AddReward" element={<AddReword />} /> */}

              {/* <Route path="newOrder" element={<NewOrder />} /> */}
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
