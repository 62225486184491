import React from 'react'
import  Reader from 'react-barcode-reader'
 
function BarcodeReader({addSerial}){

    function handleError(error){
      console.error(error)
    }

    return(
      <div>
        <Reader
          onError={handleError}
          onScan={(data)=>addSerial(data)}
          />
      </div>
    )
  }


export default BarcodeReader;