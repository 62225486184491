import { combineReducers } from 'redux';
import page from './page';
import limit from './limit';
import cart from './cart'


const rootReducer = combineReducers({
    limit,
    page,
    cart

});

export default rootReducer;