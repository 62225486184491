const initialState = 1 ;

function paginate(state = initialState, action){
    switch(action.type){
        
        case 'ADD_PAGE' : 
            state = action.data;
            return state;
       
        default : return state;

    }

}

export default paginate;