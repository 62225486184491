import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Invoice, Edit, Sale, Delete} from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function AllQuotation() {

const [sale, setSale] = useState({data:[],next:{},previous:{}});
const [setTotal] = useState({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);


useEffect(()=>{
    
    setLoader(true);
    fetch(`${url}/quotation?page=${page}&limit=${limit}&search=`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
    
        setLoader(false);
        setSrc('');
        if(data.status === true){
            setSale(data.result);
            // let TTotalPrice = 0, TPayable = 0, TDue = 0, TPaid = 0;
            // data.result.data.map((data)=>{
            //     TTotalPrice = TTotalPrice + data.totalAmount;
            //     TPayable = TPayable + data.totalPrice;
            //     TDue = TDue + data.due;
            //     TPaid = TPaid + data.paid;
            //     return(0);
            // });
    
            // setTotal({TTotalPrice, TPayable, TDue, TPaid});
        }else{
            setSale({data:[],next:{},previous:{}});
            setTotal({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});
        }

    });
    

    return () => setSale({data:[],next:{},previous:{}});

// eslint-disable-next-line react-hooks/exhaustive-deps
},[page, limit]);


function delet(id){
    fetch(`${url}/quotation/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/quotation?page=${page}&limit=${limit}&search=`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
    
                    setLoader(false);
                    setSrc('');
                    if(data.status === true){
                        setSale(data.result);
                    }else{
                        setSale({data:[],next:{},previous:{}});
                        setTotal({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});
                    }
            
                });
            }
        }else{

        }
    });
}



function search(e){
    setSrc(e.target.value);
}

function onEnter(){
    setLoader(true);
    fetch(`${url}/sale?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
    
        setLoader(false);
        setSrc('');
        if(data.status === true){
            setSale(data.result);
            let TTotalPrice = 0, TPayable = 0, TDue = 0, TPaid = 0;
            data.result.data.map((data)=>{
                TTotalPrice = TTotalPrice + data.totalAmount;
                TPayable = TPayable + data.totalPrice;
                TDue = TDue + data.due;
                TPaid = TPaid + data.paid;
                return(0);
            });
    
            setTotal({TTotalPrice, TPayable, TDue, TPaid});
        }else{
            setSale({data:[],next:{},previous:{}});
            setTotal({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});

        }

    });
    

    return () => setSale({data:[],next:{},previous:{}});
}

  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="All Quotation" rowNames={["#","Customer Name","Customer email","Customer phone","Total Price", "Discount", "Payable", "Paid", "Due","Date","Actions"]} srcVal={src} srcFunc={search} onEnter={onEnter} data={sale} width='w-full'>
    {
        sale.data.map(({_id, totalAmount, totalPrice, discount, discountType, paid, due, customerName, customerEmail, customerPhone, date}, index)=>{
            const d = new Date(date);
            return(
                <Tr key={index}>
                    <Td>{((sale.previous.page*sale.previous.limit)+1)+index}</Td>
                    <Td>{customerName}</Td>
                    <Td>{customerEmail}</Td>
                    <Td>{customerPhone}</Td>
                    <Td>{totalAmount }</Td>
                    <Td>{discount}{discountType}</Td>
                    <Td>{totalPrice}</Td>
                    <Td>{paid}</Td>
                    <Td>{due}</Td>
                    <Td>{d.toLocaleString()}</Td>
                    <Td>
                        <Edit to={"/quotation/"+_id} />
                        <Sale to={"/quotation/sale/"+_id} />
                        <Invoice to={"/quotation/invoice/"+_id} />
                        <Delete id={_id} click={delet} />
                    </Td>
                </Tr>
            );
        })
    }

    {/* <tr className=" bg-slate-400 text-black">
        <td></td>
        <td></td>
        <td className="  font-bold text-center">Total</td>
        <td></td>
        <td className=" border-x border-black text-center font-bold">{total.TTotalPrice}</td>
        <td></td>
        <td className=" border-x border-black text-center font-bold">{total.TPayable}</td>
        <td className=" border-x border-black text-center font-bold">{total.TPaid}</td>
        <td className=" border-x border-black text-center font-bold">{total.TDue}</td>
        <td></td>
        <td></td>
    </tr> */}
        
</Table>
}</>)
}

export default AllQuotation;