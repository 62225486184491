import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CurrencyToWords } from 'currency-to-words'
import url from '../../url';
import useAuth from '../../hooks/useAuth';

function InvoiceDue(){

    const [sale, setSale] = useState({_id:'', sale:{cart:[]}, paidNow:'', date:''});
    const {id} = useParams();
    const navigate = useNavigate();
    const { id:idd} =  useAuth();
    const [user, setUser] = useState({});

    useEffect(()=>{
       
        fetch(`${url}/user/${idd}`,{method:"GET", credentials:'include', mode:'cors'}).then((data)=>data.json()).then((data)=>{
            setUser(data.data);
        });
    
    },[idd]);

    useEffect(()=>{

        fetch(`${url}/sale/allPayDue/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{

            setSale(data.data[0]);

            setTimeout(() => {
                window.print();
                navigate(-1)
            }, 500);
            
        });

    },[id, navigate]);

    let total = 0;

    return(
        <div className=" w-full h-full p-2 -mt-6">
                <div className=" fixed top-0 left-0 w-full h-full bg-inv1 bg-center bg-cover"></div>
                <div className=" w-full h-14 mt-5 bg-inv2 bg-center bg-contain bg-no-repeat "></div>
                <div className=" w-full h-auto flex flex-col items-center">
                    <div>House 10 (4th Floor), Main Road, Block C, Banasree, Rampura, Dhaka 1219</div>
                    <div>09696-010506, 01966-050506</div>
                    <div>info@easysheba.com</div>

                    <span className=" bg-slate-400 text-white px-2 my-4">Due Invoice</span>
                </div>
                <div className=" w-full h-full text-sm">
                <div className=" w-full h-auto flex">
                    <div className=" w-2/5 border border-black border-r-0">
                        <div className=" w-full border-b border-black text-center font-bold"> Customer</div>
                        <h2 className=" ml-2"><span className=" font-bold"> Name: </span><span>{sale.customerName}</span></h2>
                        <h2 className=" ml-2"><span className=" font-bold"> Phone:</span> <span>{sale.customerPhone}</span></h2>
                        <h2 className=" ml-2"><span className=" font-bold"> Email: </span> <span>{sale.customerEmail}</span></h2>
                        {/* <h2 className=" ml-2"><span className=" font-bold"> Address: </span> <span>{sale.customer.address}</span></h2> */}
                    </div>
                    <div className=" w-3/5 border border-black">
                        <div>
                            <div className=" w-full border-b border-black flex">
                                <div className=" w-1/2 border-r border-black"><span className=" ml-2 font-bold">Invoice No.</span></div>
                                <div><span className=" ml-2 uppercase ">{id}</span></div>
                            </div>
                            <div className=" w-full border-b border-black flex">
                                <div className=" w-1/2 border-r border-black"><span className=" ml-2 font-bold">Sales Date & Time.</span></div>
                                <div>
                                    <span className=" ml-2">{new Date(sale.date).toDateString()}</span>
                                    <span className=" ml-2">{new Date(sale.date).toLocaleTimeString()}</span>
                                </div>
                            </div>
                            <div className=" w-full border-b border-black flex">
                                <div className=" w-1/2 border-r border-black"><span className=" ml-2 font-bold">Print Date & Time.</span></div>
                                <div>
                                    <span className=" ml-2">{new Date().toDateString()}</span>
                                    <span className=" ml-2">{new Date().toLocaleTimeString()}</span>
                                </div>
                            </div>
                            <div className=" w-full flex">
                                <div className=" w-1/2 border-r border-black"><span className=" ml-2 font-bold">Prepared By</span></div>
                                <div>
                                    <div className=" ml-2 ">{user.name} ({user.designation})</div>
                                    <div className=" ml-2 ">{user.email}</div>
                                    <div className=" ml-2 ">{user.phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className=" w-full text-center">
                    <thead>
                        <tr>
                            <th className="border border-black border-t p-1 ">SL</th>
                            <th className="border border-black border-t p-1 ">Items</th>
                            <th className="border border-black border-t p-1 ">Quantity</th>
                            <th className="border border-black border-t p-1 ">Warranty</th>
                            <th className="border border-black border-t p-1 ">Unit Price</th>
                            <th className="border border-black border-t p-1 ">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            
                            sale.sale.cart.map((data,index)=>{
                                total = total + (data.salePrice * data.quantity);
                                return(
                                <tr key={index}>
                                    <th className="border border-black border-t p-1 ">{index + 1}</th>
                                    <th className="border border-black border-t p-1 ">
                                        <span> {data.name}</span><br />
                                        <span>S/N:</span>
                                        {
                                            data.serialNumber.map((data, index)=>{
                                                return(<span key={index}> {data},</span>);
                                            })
                                        }
                                        
                                    </th>
                                    <th className="border border-black border-t p-1 ">{data.quantity}</th>
                                    <th className="border border-black border-t p-1 ">{(data.warranty === 0)? "N/A":`${data.warranty} Days`}</th>
                                    <th className="border border-black border-t p-1 ">{data.salePrice}</th>
                                    <th className="border border-black border-t p-1 ">{data.salePrice * data.quantity}</th>
                                </tr>
                                );
                            })
                        }

                            <tr >
                                <td></td>
                                <td></td>
                                <th></th>
                                <td></td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Total Amount:</th>
                                <td className="  ">{total}  BDT</td>
                            </tr>

                            <tr className=" border-b-[24px] border-transparent "></tr>

                            
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Vat/Tax :</th>
                                <td className="  ">{sale.sale.vat}  %</td>
                            </tr>

                            <tr className=" relative">
                                <td></td>
                                <td className=" absolute top-0 left-0"> <b>IN WORD :</b>{` ${CurrencyToWords(sale.sale.totalPrice, 'Taka', 'Poisha' ).toUpperCase()} ONLY.`}</td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Discount :</th>
                                <td className=" ">{sale.sale.discount}  {sale.sale.discountType}</td>
                            </tr>

                            
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Payable :</th>
                                <td className="  ">{sale.sale.totalPrice} BDT</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className=" border-slate-500 border-b text-left">Paid :</th>
                                <td className=" border-slate-500 border-b  ">{sale.sale.paid}  BDT</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left">Due :</th>
                                <td className=" ">{sale.sale.due}  BDT</td>
                            </tr>
                        
                    </tbody>
                </table>
                        <h1 className=" mt-10">
                            <b>Warranty Void : </b>
                            <span>Sticker removed items,  Burned case and physically damaged goods.</span>
                        </h1>
                <div className=" w-full h-auto absolute bottom-4 left-2 flex justify-evenly">
                    <div className=" flex flex-col items-center">
                        <div>
                            <span className=" font-bold border-slate-400 border-t px-2">Customer Signature</span>
                        </div>
                        <div className=" flex flex-col items-center mt-1">
                            <span className=" font-bold">Sales</span>
                            <span className=" font-bold">+88 01407069891</span>
                            <span className=" font-bold">+88 09696559891</span>
                        </div>
                    </div>
                    <div className=" flex flex-col items-center">
                        <span className=" font-bold">Helpline</span>
                        <span className=" font-bold">01966-050506</span>
                        <span className=" font-bold">09696-010506</span>
                    </div>
                    <div className=" flex flex-col items-center">
                        <div>
                            <span className=" font-bold border-slate-400 border-t px-2">Authorized Signature</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        // <div className=" w-full h-full p-10 -mt-14">
        //     <div className=" w-60 h-60 bg-inv2 bg-center bg-cover "></div>
        //         <div className=" w-full h-full bg-inv1 bg-center bg-cover">
        //         <h1 className=" uppercase text-xl font-bold pl-20 pb-10">Invoice</h1>
        //         <div className=" w-full pl-20 flex justify-between">
        //             <div className=" w-96">
        //                 <h1 className=" font-bold">To:</h1>
        //                 <h2><span className=" font-bold">Name: </span><span>{sale.sale.customerName}</span></h2>
        //                 <h2><span className=" font-bold">Phone:</span> <span>{sale.sale.customerPhone}</span></h2>
        //                 <h2><span className=" font-bold">Email: </span> <span>{sale.sale.customerEmail}</span></h2>
        //                 {/* <h2><span className=" font-bold">Address: </span> <span>{sale.sale.customer.address}</span></h2> */}
        //             </div>
        //             <div className=" w-96">
        //                 <h2><span className=" font-bold">Order Date: </span> <span>{dat}</span></h2>
        //                 <h2 className=" uppercase pr-8"><span className=" font-bold">ID: </span> <span>{id}</span></h2>
        //             </div>
        //         </div>

        //         <table className=" w-full table-auto border-collapse border-slate-500 mt-10">
        //                 <thead>
        //                     <tr className=" text-left border-slate-500 border-b">
        //                         <th>Item Name</th>
        //                         <th>Price</th>
        //                         <th>Vat/Tax</th>
        //                         <th>Quantity</th>
        //                         <th>Total</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {
        //                         sale.sale.cart.map((data,index)=>{
        //                             return(
        //                             <tr key={index} className=" border-slate-500 border-b">
        //                             <td>{data.name}</td>
        //                             <td>{data.salePrice}  ৳</td>
        //                             <td>{data.vat}%</td>
        //                             <td>{data.quantity}</td>
        //                             <td>{((data.salePrice * data.quantity))}  ৳</td>
        //                         </tr>)
        //                         })
        //                     }

        //                     <tr >
        //                         <td></td>
        //                         <td></td>
        //                         <th></th>
        //                         <td></td>
        //                     </tr>

        //                     <tr >
        //                         <td></td>
        //                         <td></td>
        //                         <th className="  text-left">Total :</th>
        //                         <td className="  ">{sale.sale.totalAmount}  ৳</td>
        //                     </tr>

        //                     <tr className=" border-b-[24px] border-transparent "></tr>

        //                     <tr >
        //                         <td></td>
        //                         <td></td>
        //                         <th className="  text-left">Discount :</th>
        //                         <td className=" ">{sale.sale.discount}  ৳</td>
        //                     </tr>

        //                     <tr >
        //                         <td></td>
        //                         <td></td>
        //                         <th className="  text-left">Payable :</th>
        //                         <td className="  ">{sale.sale.totalPrice}  ৳</td>
        //                     </tr>
        //                     <tr >
        //                         <td></td>
        //                         <td></td>
        //                         <th className=" border-slate-500 border-b text-left">Paid Now:</th>
        //                         <td className=" border-slate-500 border-b  ">{sale.paidNow}  ৳</td>
        //                     </tr>
        //                     <tr >
        //                         <td></td>
        //                         <td></td>
        //                         <th className="  text-left">Due :</th>
        //                         <td className=" ">{sale.sale.due}  ৳</td>
        //                     </tr>
        //                 </tbody>
        //             </table>

        //     </div>
        // </div>
    );
}

export default InvoiceDue;