import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Due, Invoice, Return, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function ThisMonthPurchase() {

const [purchase, setPurchase] = useState({data:[],next:{},previous:{}});
const [total, setTotal] = useState({ TPayable:0, TDue:0, TPaid:0});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);


useEffect(()=>{

    setLoader(true);
    fetch(`${url}/report/thisMonthPurchase?page=${page}&limit=${limit}&search=`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
    
        setLoader(false);
        setSrc('');
        if(data.status === true){
            setPurchase(data.result);
            let TTotalPrice = 0, TPayable = 0, TDue = 0, TPaid = 0;
            data.result.data.map((data)=>{
    
                TPayable = TPayable + data.totalPrice;
                TDue = TDue + data.due;
                TPaid = TPaid + data.paid;
                return(0);
            });
    
            setTotal({TTotalPrice, TPayable, TDue, TPaid});
        }else{
            setPurchase({data:[],next:{},previous:{}});
            setTotal({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});
        }
    });

    return () => setPurchase({data:[],next:{},previous:{}});

},[page, limit]);

function search(e){
    setSrc(e.target.value);
}

function onEnter(){
    setLoader(true);
    setSrc('');
    fetch(`${url}/report/thisMonthPurchase?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
    
        setLoader(false);
        setSrc('');
        if(data.status === true){
            setPurchase(data.result);

            let TTotalPrice = 0, TPayable = 0, TDue = 0, TPaid = 0;
            data.result.data.map((data)=>{
    
                TPayable = TPayable + data.totalPrice;
                TDue = TDue + data.due;
                TPaid = TPaid + data.paid;
                return(0);
            });
    
            setTotal({TTotalPrice, TPayable, TDue, TPaid});
        }else{
            setPurchase({data:[],next:{},previous:{}});
            setTotal({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});
        }

    });
    
    return () => setPurchase({data:[],next:{},previous:{}});

}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="This Month Purchase" rowNames={["#","Supplier Name","Supplier email","Supplier phone","Total Price","Payable", "Paid", "Due","Date","Actions"]} srcVal={src} srcFunc={search} onEnter={onEnter} data={purchase} width='w-full'>
        {
            purchase.data.map(({_id, totalPrice, paid, due, supplierName, supplierEmail, supplierPhone, date}, index)=>{
                const d = new Date(date);
                return(
                    <Tr key={index}>
                        <Td>{((purchase.previous.page*purchase.previous.limit)+1)+index}</Td>
                        <Td>{supplierName}</Td>
                        <Td>{supplierEmail}</Td>
                        <Td>{supplierPhone}</Td>
                        <Td>{totalPrice }</Td>
                        <Td>{totalPrice}</Td>
                        <Td>{paid}</Td>
                        <Td>{due}</Td>
                        <Td>{d.toLocaleString()}</Td>
                        <Td>
                            <View to={"/purchase/view/"+_id} />
                            {
                                (due > 0)?<Due to={"/purchase/due/"+_id} />:<></>
                            }
                            
                            <Invoice to={"/purchase/invoice/"+_id} />
                            <Return to={"/return/purchase/"+_id} />
                        </Td>
                    </Tr>
                );
            })
        }

        <tr className=" bg-slate-400 text-black">
            <td></td>
            <td></td>
            <td className="  font-bold text-center">Total</td>
            <td></td>
            <td className=" border-x border-black text-center font-bold">{total.TPayable}</td>
            <td className=" border-x border-black text-center font-bold">{total.TPayable}</td>
            <td className=" border-x border-black text-center font-bold">{total.TPaid}</td>
            <td className=" border-x border-black text-center font-bold">{total.TDue}</td>
            <td></td>
            <td></td>
        </tr>
            
    </Table>
}</>)
}

export default ThisMonthPurchase;