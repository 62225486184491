import React from 'react';
import { useEffect } from 'react';
import url from '../url';
import useAuth from '../hooks/useAuth';
import { useState } from 'react';
import Loader from '../components/Loader';


function Profile(){
    const [user, setUser] = useState({});
    const { id } = useAuth();
    const [loader, setLoader] = useState(true);


    useEffect(()=>{
        setLoader(true);
        fetch(`${url}/user/${id}`,{method:"GET", credentials:'include', mode:'cors'}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setUser(data.data);
        });
    
    },[id]);


    return(
        <section className=" w-full h-full flex items-center justify-center">
        { loader?<div className=' absolute w-full flex justify-center mt-5 '><Loader /></div>:
            <div className=" w-[290px] h-max p-5 rounded-md bg-slate-100 flex flex-col items-center justify-center">
                <div className=" w-[250px] h-[250px] rounded-full bg-center bg-cover bg-no-repeat cursor-pointer select-none relative" style={{ backgroundImage: `url('${user.img}'`}} ></div>
                <div>
                    <span className=' pt-2 font-bold'>{user.name}</span>
                </div>
                <div className=' p-5'>
                    <div>
                        <span className=" font-bold">Email: </span>
                        <span>{user.email}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Phone: </span>
                        <span>{user.phone}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Designation: </span>
                        <span>{user.designation}</span>
                    </div>
                    {/* <div>
                        <span className=" font-bold">Role: </span>
                        <span>{user.role}</span>
                    </div> */}
                </div>
            </div>
        }
        </section>
    )
}

export default Profile;