import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Due, Invoice, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';


function SupplierDetails(){
    const [supplier, setSupplier] = useState({sales:[]});
    const [supplierReturn, setSupplierReturn] = useState({data:[],next:{},previous:{}});
    const [sale, setSale] = useState({data:[],next:{},previous:{}});
    const [ payment, setPayment] = useState({});
    const [payDueList, setPayDueList] = useState({data:[],next:{},previous:{}});
    const page = useSelector((state)=>state.page);
    const limit = useSelector((state)=>state.limit);
    const [src, setSrc] = useState('');
    const [loader, setLoader] = useState(true);
    const { id } = useParams();

    useEffect(()=>{
        fetch(`${url}/supplier/${id}`,{method:"GET", mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            setSupplier(data.data[0]);
        });

        fetch(`${url}/purchase/supplierTotalInfo/${id}?page=${page}&limit=${limit}`,{method:"GET", mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setPayment(data.data[0]);
            }
        });

        fetch(`${url}/purchase/return/supplier/${id}?page=${page}&limit=${limit}`,{method:"GET", mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setSupplierReturn(data.result);
            }
        });

    },[id, limit, page]);


    useEffect(()=>{
        setLoader(true);
        fetch(`${url}/purchase/supplier/${id}?page=${page}&limit=${limit}`,{method:"GET", mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            setLoader(false)
            if(data.status === true){
                setSale(data.result);
            }
        });

        fetch(`${url}/purchase/supplierPaidDue/${id}?page=${page}&limit=${limit}`,{method:"GET", mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            setLoader(false)
            if(data.status === true){
                setPayDueList(data.result);  
            }
        });

    },[id, limit, page]);


    function search(e){
        setSrc(e.target.value);
    }
    

    return(<>
        <div>
            <div className=" w-[500px] h-auto p-4 mx-auto mt-10 bg-slate-100 shadow2">
                <div>
                    <span className=" font-bold">Name : </span>
                    <span>{supplier.name}</span>
                </div>
                <div>
                    <span className=" font-bold">Email : </span>
                    <span>{supplier.email}</span>
                </div>
                <div>
                    <span className=" font-bold">Phone : </span>
                    <span>{supplier.phone}</span>
                </div>
                <div>
                    <span className=" font-bold">Address : </span>
                    <span>{supplier.address}</span>
                </div>
                <div>
                    <span className=" font-bold">Total Payable : </span>
                    <span>{payment.totalPayable}  ৳</span>
                </div>
                <div>
                    <span className=" font-bold">Total Paid : </span>
                    <span>{payment.totalPaid}  ৳</span>
                </div>
                <div>
                    <span className=" font-bold">Total Due : </span>
                    <span>{payment.totalDue}  ৳</span>
                </div>
            </div>
        {
            loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
            <Table to="" name="Purchase Table" rowNames={["#","Supplier Name","Supplier email","Supplier phone","Total Price","Payable", "Paid", "Due","Date","Actions"]} srcVal={src} srcFunc={search} data={sale} width='w-full'>
        {
            sale.data.map(({_id, totalPrice, paid, due, supplierName, supplierEmail, supplierPhone, date}, index)=>{
                const d = new Date(date);
                return(
                    <Tr key={index}>
                        <Td>{((sale.previous.page*sale.previous.limit)+1)+index}</Td>
                        <Td>{supplierName}</Td>
                        <Td>{supplierEmail}</Td>
                        <Td>{supplierPhone}</Td>
                        <Td>{totalPrice }</Td>
                        <Td>{totalPrice}</Td>
                        <Td>{paid}</Td>
                        <Td>{due}</Td>
                        <Td>{d.toLocaleString()}</Td>
                        <Td>
                            <View to={"/purchase/view/"+_id} />
                            {
                                (due > 0)?<Due to={"/purchase/due/"+_id} />:<></>
                            }
                            
                            <Invoice to={"/purchase/invoice/"+_id} />
                        </Td>
                    </Tr>
                );
            })
        }
            
    </Table>
    }
    </div>
    <div>
        {
        loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
        <Table to="" name="Paid Due Table" rowNames={["#","Supplier Name","Supplier email","Supplier phone","Payable", "Paid  Now", "Due Now","Date","Actions"]} srcVal={src} srcFunc={search} data={payDueList} width='w-full'>
    {
        payDueList.data.map(({_id, purchase : dueData, paidNow, due,  date}, index)=>{
            const d = new Date(date);
            return(
                <Tr key={index}>
                    <Td>{((payDueList.previous.page*payDueList.previous.limit)+1)+index}</Td>
                    <Td>{dueData.supplierName}</Td>
                    <Td>{dueData.supplierEmail}</Td>
                    <Td>{dueData.supplierPhone}</Td>
                    <Td>{dueData.totalPrice }</Td>
                    <Td>{paidNow}</Td>
                    <Td>{dueData.due}</Td>
                    <Td>{d.toLocaleString()}</Td>
                    <Td>
                        {/* <View to={"/purchase/view/"+_id} /> */}
                        {
                            (dueData.due > 0)?<Due to={"/purchase/due/"+dueData._id} />:<></>
                        }
                        <Invoice to={"/purchase/due/invoice/"+_id} />
                    </Td>
                </Tr>
            );
        })
    }
        
</Table>
    }
    </div>
    <div>
    <Table to="" name="Return To Supplier" rowNames={["#","Supplier Name","Supplier email","Supplier phone","Total Price","Receivable", "Received", "Due","Date","Actions"]} srcVal={src} srcFunc={search} data={supplierReturn} width='w-full'>
        {
            supplierReturn.data.map(({_id, totalPrice, paid, due, supplierName, supplierEmail, supplierPhone, date}, index)=>{
                const d = new Date(date);
                return(
                    <Tr key={index}>
                        <Td>{((supplierReturn.previous.page*supplierReturn.previous.limit)+1)+index}</Td>
                        <Td>{supplierName}</Td>
                        <Td>{supplierEmail}</Td>
                        <Td>{supplierPhone}</Td>
                        <Td>{totalPrice }</Td>
                        <Td>{totalPrice}</Td>
                        <Td>{paid}</Td>
                        <Td>{due}</Td>
                        <Td>{d.toLocaleString()}</Td>
                        <Td>
                            <View to={"/supplier/return/view/"+_id} />
                            {
                                // (due > 0)?<Due to={"/purchase/due/"+_id} />:<></>
                            }
                            
                            <Invoice to={"/supplier/return/invoice/"+_id} />
                            {/* <Return to={"/return/purchase/"+_id} /> */}
                        </Td>
                    </Tr>
                );
            })
        }
            
    </Table>
    </div>
    </>)
}

export default SupplierDetails;