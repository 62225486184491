const initialState = [] ;

function cart(state = initialState, action){
    switch(action.type){
        case 'ADD_TO_CART' :

            const d = state.filter((data)=>{
                return( data.id === action.data.id);
            });

            if(d.length > 0){
                const nd = state.map((data)=>{
                    if(data.id === action.data.id){
                        data = action.data;
                    }

                    return(data);
                });
                state = nd;
                return state;
            }else{
                state = [...state, action.data];
            }

            return state;
        
        
        case 'ADD_QUANTITY' :
            const s = state.map((data)=>{
                if(data.id === action.data.id){
                    data.quantity =  data.quantity + 1;
                }

                return(data);
            })


            state = s;

            return state;

            case 'ADD_QUANTITY_QU' :
                const sq = state.map((data)=>{
                    if(data.id === action.data.id){
                        data.NDquantity =  data.NDquantity + 1;
                    }
    
                    return(data);
                })
    
    
                state = sq;
    
                return state;
    


        case 'REMOVE_QUANTITY' :
            
            let rid ;

            const ss = state.map((data)=>{
                if(data.id === action.data.id){
                    if(data.quantity === 1){
                        rid = data.id;
                    }else{
                        data.quantity =  data.quantity - 1;
                    }
                }

                return(data);
            })

            const a = ss.filter((data)=>{
                return(data.id !== rid)
  
            })


            state = a;

            return state;

            case 'REMOVE_QUANTITY_QU' :
            
            let ridq ;

            const ssq = state.map((data)=>{
                if(data.id === action.data.id){
                    if(data.NDquantity === 1){
                        ridq = data.id;
                    }else{
                        data.NDquantity =  data.NDquantity - 1;
                    }
                }

                return(data);
            })

            const aq = ssq.filter((data)=>{
                return(data.id !== ridq)
  
            })


            state = aq;

            return state;
            

        case 'REMOVE_FORM_CART' :
            const dcc = state.filter((data)=>{
                return( data.id !== action.data);
            });

            state = [...dcc];

            return state;

        case 'ADD_CART' :
            state = action.data;

            return state;

        case 'CANCEL_ORDER' :

            state = [];

            return state;

            case 'EDIT_ORDER' :

                state = action.data;
    
                return state;

        default : return state;
    }

}

export default cart;