import React from 'react';

function Dashboard(){

    return (
        <>
            <div className='w-[1200px] h-[500px] bg-slate-700'></div>
        </>
    )
}

export default Dashboard;