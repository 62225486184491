import React from 'react';
import loader from '../assets/images/loading.gif';
function Loader(){
    return(
        <div className=" absolute w-full h-screen flex justify-center py-10">
            <img className=" w-10 h-10" src={loader} alt="" />
        </div>
    )
}

export default Loader;