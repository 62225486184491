import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Textarea, Form1 } from  '../../components/Input';
import url from '../../url';

function EditCategory() {
  const [value, setValue] = useState({name:'', description:'', price:0, quantity:0, date:''});
  const navigate = useNavigate();
  const {id} = useParams();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/accessories/${id}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }


  useEffect(()=>{

    fetch(`${url}/accessories/${id}`,{
        method:"GET",
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          const date = new Date(data.data[0].date);

          setValue({name: data.data[0].name, description: data.data[0].description, price: data.data[0].price, quantity: data.data[0].quantity, date: `${date.getFullYear()}-${date.getMonth() +1}-${date.getDate()}`});
        }
    });

  },[id]);



  return (
    <Form1 title="Edit Accessories">
        <Input1 onChange={set} name="name" placeholder="Enter accessories name:" value={value.name} label="Accessories name:" id="name" />
        <Textarea onChange={set} name="description" placeholder="Enter accessories description:" value={value.description} label="Accessories description:" id="description" />
        <Input1 onChange={set} type="number" name="price" placeholder="Enter accessories price:" value={value.price} label="Accessories price:" id="price"/>
        <Input1 onChange={set} type="number" name="quantity" placeholder="Enter accessories quantity:" value={value.quantity} label="Accessories quantity:" id="quantity"/>
        <Input1 onChange={set} type="date" name="date" placeholder="Enter accessories date:" value={value.date} label="Accessories date:" id="date"/>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}
export default EditCategory;