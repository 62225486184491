import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Nav from './Nav';
import SideBar from './SideBar';

function Layout({children, nav}){
    const [min, setMin] = useState(false);

    function toggleMin(){
        if(min === true){
            setMin(false);
        }else{
            setMin(true);
        }
    }



    return(
        <section>
            <SideBar min={min} />
            <Nav toggleMin={toggleMin} min={min} nav={nav}>
            </Nav>
            <div className={` -z-20 relative float-left w-[calc(100%-70px)] ${min?'md:w-[calc(100%-70px)]':'md:w-[calc(100%-300px)]'} h-[calc(100vh-70px)] overflow-auto bg-slate-50`}>
                {
                    children
                }
                <Outlet />
            </div>
        </section>
    )
}

export default Layout;