import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import url from '../url';
import { ADD_TO_CART, REMOVE_FORM_CART, CANCEL_ORDER, ADD_QUANTITY_QU, REMOVE_QUANTITY_QU } from '../store/actions/cart';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';
// import BarcodeReader from '../components/BarcodeReader';

function Quotation(){

    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [loader, setLoader] = useState(false);
    const [src, setSrc] = useState("");
    const [srcC, setSrcC] = useState("");
    const [cat, setCat] = useState("");
    const dispatch = useDispatch();
    const cart = useSelector((state)=> state.cart);
    const [cartInfo, setCartInfo] = useState({id:'', name:'', img:'', salePrice:'', purchasePrice:'', serial:'', warranty:'', quantity:0, NDquantity:1, serialNumberStatus:true, serialNumbers:[],inStockSerialNumbers:[]});
    const [serialNumber, setSerialNumber] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [discountType, setDiscountType] = useState("%");
    const [discount, setDiscount] = useState(0);
    const [paid, setPaid] = useState(0);
    const [vat, setVat] = useState(0);
    const [customerS, setCustomerS] = useState("");
    const [alert, setAlert] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(false);


    function getBySearch(sr){

        setLoader(true);
        fetch(`${url}/product/search?search=${sr}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setCat(" ")
            setLoader(false);
            if(data.status === true){
                setProduct(data.result.data);
            }else{
                setProduct([]);
            }
        });
    }

    function getCustomerBySearch(){

        setLoader(true);
        fetch(`${url}/customer/search?search=${srcC}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setSrcC("");
            setLoader(false);
            if(data.status === true){
                setCustomer(data.result.data);
            }else{
                setCustomer([]);
            }
        });
    }

    function getByCategory(id){
        setSrc("");
        setLoader(true);
        fetch(`${url}/product/search?search=${id}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setProduct(data.result.data);
            }else{
                setProduct([]);
            }
        });
    }


    useEffect(()=>{
        dispatch(CANCEL_ORDER());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{

        setLoader(true);
        fetch(`${url}/category`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setCategory(data.result.data);
        });

        fetch(`${url}/product`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setProduct(data.result.data);
        });

        fetch(`${url}/customer`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setCustomer(data.result.data);
        });

    },[]);

    function inCart(id){
        const d = cart.filter((data)=>{
            return( data.id === id);
        });

        if(d.length > 0){
            return true;
        }else{
            return false;
        }
    }

    // function quantityIn(id){
    //     const d = cart.filter((data)=>{
    //         return( data.id === id);
    //     });
    //     if(d.length > 0){
    //         return d[0].quantity;
    //     }else{
    //         return 0;
    //     }
    // }

    
    function quantityIn(id){
        const d = cart.filter((data)=>{
            return( data.id === id);
        });
        if(d.length > 0){
            return d[0].NDquantity;
        }else{
            return 0;
        }
    }

    function addProduct(data){
        setCartInfo({...cartInfo, id: data.id, name: data.name, img:data.img, serial:'', warranty:data.warranty, purchasePrice:data.purchasePrice, salePrice: data.salePrice, quantity:0, NDquantity:1, serialNumberStatus:data.serialNumber, serialNumbers:data.serialNumbers,inStockSerialNumbers:data.inStockSerialNumbers,   serialNumber:[]});
        setAlert([])
    }

    // function addSerial(data){
    //     if(cartInfo.serialNumbers.includes(data)){
    //         if(cartInfo.inStockSerialNumbers.includes(data)){
    //             if(!serialNumber.includes(data)){
    //                 setSerialNumber([...serialNumber, cartInfo.serial]);
    //             }
    //             setCartInfo({...cartInfo, serial:''});
    //         }else{
    //             setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="This serial number product is already sold out.!" />]);
    //         }
    //     }else{
    //         setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="This serial number product is not purchase yet!" />]);
    //     }

    // }

    // function addSerialByScanner(data){
    //     if(cartInfo.serialNumberStatus === true){
    //         if(cartInfo.serialNumbers.includes(data)){
    //             if(cartInfo.inStockSerialNumbers.includes(data)){
    //                 if(!serialNumber.includes(data)){
    //                     setSerialNumber([...serialNumber, data]);
    //                 }
    //                 setCartInfo({...cartInfo, serial:''});
    //             }else{
    //                 setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="This serial number product is already sold out.!" />]);
    //             }
    //         }else{
    //             setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="This serial number product is not purchase yet!" />]);
    //         }
    //     }
    // }

    // function removeSerial(id){
    //     let sd = serialNumber.filter((data)=>{
    //         return(data !== id);
    //     });

    //     setSerialNumber(sd);
    // }

    function addToCart(){
        if(cartInfo.id !== ''){
            // if(cartInfo.serialNumberStatus === true){
            //     if(serialNumber.length > 0){
            //         dispatch(ADD_TO_CART({id:cartInfo.id, name:cartInfo.name, img:cartInfo.img, salePrice:cartInfo.salePrice, purchasePrice:cartInfo.purchasePrice, vat:cartInfo.vat, serialNumberStatus:cartInfo.serialNumberStatus, quantity: serialNumber.length, serialNumber, serial:''}));
            //         setCartInfo({id:'', name:'', img:'', salePrice:'', purchasePrice:'', serial:''});
            //         setSerialNumber([]);
            //     }
            // }else{
                dispatch(ADD_TO_CART({id:cartInfo.id, name:cartInfo.name, img:cartInfo.img, salePrice:cartInfo.salePrice, warranty: cartInfo.warranty, purchasePrice:cartInfo.purchasePrice, vat:cartInfo.vat, serialNumberStatus:cartInfo.serialNumberStatus, quantity:0, NDquantity: cartInfo.NDquantity, serialNumber, serial:''}));
                setCartInfo({id:'', name:'', img:'', salePrice:'', purchasePrice:'', warranty:'', serial:''});
                setSerialNumber([]);
                
            // }

        }
    }

    // useEffect(()=>{
    //     if( cart.length !== 0){
    //         const salePrice = cart.reduce((total, data)=>{
    //                 return total + (((data.salePrice * data.quantity) +(data.salePrice * data.quantity * data.vat)/100));
    //         }, 0);

    //         setTotalPrice(salePrice);
            
    //     }else{
    //         setTotalPrice(0);
    //     }
    // },[cart]);


    useEffect(()=>{
        if( cart.length !== 0){
            const salePrice = cart.reduce((total, data)=>{
                    return total + ( ((data.salePrice * data.NDquantity )));
            }, 0);

            const purchasePrice = cart.reduce((total, data)=>{
                return total + ((data.purchasePrice * data.NDquantity) + ((data.purchasePrice * data.NDquantity )/100));
            }, 0);

            setTotalAmount(salePrice + (salePrice * vat)/100);

            if(discountType === "%"){
                setTotalPrice((salePrice - ((salePrice*discount)/100) + (salePrice * vat)/100));
                setTotalProfit(((salePrice - purchasePrice) -((purchasePrice*discount)/100) - (salePrice * vat)/100 ));
            }else{
                setTotalPrice((salePrice - discount + (salePrice * vat)/100));
                setTotalProfit(((salePrice - purchasePrice) - discount - (salePrice * vat)/100 ));
            }
            
        }else{
            setTotalPrice(0);
        }
    },[cart, discount, discountType, vat]);

    // function save(){

    //     if(customerS === ""){
    //         setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Please select a customer!" />]);
    //     }else{

    //         if(cart.length === 0){
    //             setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Cart is empty!" />]);
    //         }else{
    //             fetch(`${url}/sale`,{ 
    //                 method:"POST",
    //                 credentials:"include",
    //                 mode:"cors",
    //                 body: JSON.stringify({customer:customerS, discountType, discount, paid, totalAmount, totalPrice, totalProfit, cart})
    //             }).then((data)=>data.json()).then((data)=>{
    //                 const wind = window.open("/sale/invoice/"+data.id);
                    
    //                 setTimeout(() => {
    //                     wind.close();
    //                 }, 2000);


    //                 setLoader(true)
    //                 fetch(`${url}/product`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
    //                     setLoader(false);
    //                     setProduct(data.result.data);
    //                 });

    //                 setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message="Order placed successfully." />]);

    //                 dispatch(CANCEL_ORDER()); 
    //                 setPaid(0);
    //                 setCustomerS("");
    //                 setDiscount(0);
    //                 setDiscountType("%");
    //             })
    //         };
    //     }

    // }


    function saveQuotation(){

        if(customerS === ""){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Please select a customer!" />]);
        }else{

            if(cart.length === 0){
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Cart is empty!" />]);
            }else{
                setButtonDisable(true);
                fetch(`${url}/quotation`,{ 
                    method:"POST",
                    credentials:"include",
                    mode:"cors",
                    body: JSON.stringify({customer:customerS, discountType, discount, paid, totalAmount, totalPrice, totalProfit, cart, vat})
                }).then((data)=>data.json()).then((data)=>{

                    const wind = window.open("/quotation/invoice/"+data.id);
                    
                    setTimeout(() => {
                        wind.close();
                    }, 2000);


                    setLoader(true)
                    fetch(`${url}/product`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
                        setLoader(false);
                        setProduct(data.result.data);
                    });

                    setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message="Quotation saved successfully." />]);

                    dispatch(CANCEL_ORDER()); 
                    setPaid(0);
                    setCustomerS("");
                    setDiscount(0);
                    setDiscountType("%");
                    setButtonDisable(false);
                })
            };
        }

    }





    return(
        <>
            <section className=" hidden lg:block w-full h-[calc(100vh-152px)] select-none">
                {
                    loader?<Loader />:<></>
                }
                <div className= " absolute top-10 right-2">
                    <AlertContainer>
                        {alert}
                    </AlertContainer>
                </div>
                {
                    (cartInfo.id !== '')?
                    <div className=' w-full h-full bg-slate-500/60 absolute top-0 left-0 z-20 flex items-center justify-center'>
                        {/* <BarcodeReader addSerial={addSerialByScanner} /> */}
                        <div className= " absolute top-0 right-2 z-50">
                            <AlertContainer>
                                {alert}
                            </AlertContainer>
                        </div>
                        <div className=' w-[700px] min-h-[500px] bg-slate-300 rounded-md flex relative'>
                        <button onClick={()=>{ setCartInfo({id:'', name:'', img:'', salePrice:'', purchasePrice:'', serial:'', warranty:'',}); setSerialNumber([])}} className=" absolute top-0 right-2 text-2xl text-red-600 px-1 -py-2"><i className="fa-solid fa-xmark"></i></button>
                            <div style={{ backgroundImage: `url('${cartInfo.img}'`}} className=' w-1/2 min-h-[500px] bg-no-repeat bg-center bg-cover rounded-tl-md rounded-bl-md'>

                            </div>
                            <div className=" p-4">
                                <div>
                                    <h1 className=" text-center text-nav2 font-bold">{cartInfo.name}</h1>
                                </div>
                                <div className=' mt-4'>
                                    <label className=" cursor-pointer" htmlFor="salePrice">Sale Price :</label><br />
                                    <input className=' shadow1 border-none outline-none px-4' onChange={(e)=>setCartInfo({...cartInfo, salePrice: e.target.value})} type="number" name="salePrice" value={cartInfo.salePrice} id="salePrice" />
                                </div>
                                {/* {
                                    (cartInfo.serialNumberStatus === true)?
                                
                                <>
                                    <div className=' mt-4'>
                                        <h1 className=" text-center text-nav2 font-bold py-2"><span>Quantity : {serialNumber.length}</span></h1>
                                        <label className=" cursor-pointer" htmlFor="serial">Serial Number  (<span className=" text-[10px] text-red-800">Can Use Scanner</span>):</label><br />
                                            {
                                                (serialNumber.length > 0)?
                                                <div className=" max-h-[210px] overflow-auto shadow1 p-4">
                                                    {
                                                        serialNumber.map((data, index)=>{
                                                            return(
                                                                <div className="py-[1px] text-slate-700"  key={index}>
                                                                    <span>{index + 1}. {data}</span>
                                                                    <button onClick={()=>removeSerial(data)} className=" text-red-600 px-1 -py-2"><i className="fa-solid fa-xmark"></i></button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>:<></>
                                            }
                                        <input className=' shadow1 border-none outline-none px-4 mt-4' onChange={(e)=>setCartInfo({...cartInfo, serial: e.target.value})} type="text" name="serial" value={cartInfo.serial} id="serial" />
                                        <button onClick={()=>addSerial(cartInfo.serial) } className=" bg-nav2 hover:bg-nav2hover text-white px-2 rounded-full ml-2">Add</button>
                                    </div>
                                    <div className=' mt-5 text-nav2 font-bold  text-center'>
                                        <span>Total: </span>
                                        <span>{cartInfo.salePrice * serialNumber.length}</span>
                                    </div>
                                </>: <></>
                                } */}
                                <button onClick={addToCart} className=" w-full bg-nav2 hover:bg-nav2hover text-white mt-3 rounded-full">Add to Cart</button>
                            </div>
                        </div>
                    </div>:<></>
                }
                <div className=" w-[60%] h-[calc(100vh-152px)] p-2 float-left">
                    <div className=" w-full h-10 flex">
                        <input onChange={(e)=>{ getBySearch(e.target.value); setSrc(e.target.value)}} className=' w-full p-1 pl-4 border-none outline-none shadow1 text-nav2 placeholder-nav2' type="text" name="search" id="search" value={src} placeholder="Search product..." />
                        {/* <button onClick={getBySearch} className=" w-11 h-10 mx-2 flex items-center justify-center shadow3 text-nav2">
                            <i className="fa-solid fa-magnifying-glass text-xl"></i>
                        </button> */}
                    </div>
                    <div className=" w-[100%] flex mt-2">
                        <div className=" w-[150px] h-[calc(100vh-210px)] p-2 shrink-0 shadow1 overflow-y-auto">
                            <button onClick={()=>{getByCategory(" "); setCat("")}} className={` w-full mt-3 px-2 py-1 text-lg text-nav2 ${(cat === "")? "shadowActive": "shadow3" }`}>All Product</button>
                            {
                                category.map((data, index)=>{
                                    return(
                                        <button onClick={()=>{getByCategory(data._id); setCat(data._id)}} key={index} className={` w-full mt-3 px-2 py-1 text-lg text-nav2 ${(cat === data._id)? "shadowActive": "shadow3" }`}>{data.name}</button>
                                    )
                                })
                            }
                        </div>
                        <div className=" w-full h-[calc(100vh-210px)] ml-2 p-4 shadow1 overflow-y-auto flex flex-wrap gap-4 justify-evenly items-start">
                            {
                                product.map((data, index)=>{

                                    return(
                                        <div key={index}>
                                        {
                                            inCart(data._id)?
                                            <button className=" w-[140px] h-[180px] relative shadow1 rounded-[12px!important] p-1 flex flex-col items-center justify-center cursor-not-allowed">
                                                <div className=" w-[120px] h-[100px] bg-slate-300 bg-center bg-cover bg-no-repeat rounded-md" style={{ backgroundImage: `url(${data.img})`}}>
                                                <div className=" absolute z-10 top-0 right-0 px-[2px] rounded-full bg-rose-700 text-white"><span>{data.inStock - quantityIn(data._id)}</span></div>
                                                </div>
                                                <h1 className=" text-[14px] pt-1 text-black font-bold">{data.name} </h1>
                                            </button>
                                            :
                                            <button onClick={()=>{addProduct({id:data._id, name:data.name, purchasePrice:data.purchasePrice, salePrice:data.salePrice,  NDquantity:1, serialNumber:data.serialNumber, warranty: data.warrantyDays, serialNumbers:data.serialNumbers,inStockSerialNumbers:data.inStockSerialNumbers, img:data.img}); setAlert([]); setSrc("")}} className=" w-[140px] h-[180px] relative shadow3 rounded-[12px!important] p-1 flex flex-col items-center justify-center cursor-pointer">
                                                <div className=" w-[120px] h-[100px] bg-slate-300 bg-center bg-cover bg-no-repeat rounded-md" style={{ backgroundImage: `url(${data.img})`}}>
                                                    <div className=" absolute z-10 top-0 right-0 px-[2px] rounded-full bg-rose-700 text-white"><span>{data.inStock}</span></div>
                                                </div>
                                                <h1 className=" text-[14px] pt-1 text-black font-bold">{data.name}</h1>
                                            </button>
                                        }
            
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className=" w-[40%] h-[calc(100vh-152px)] px-2 bg-slate-200 float-left">
                    <div className='w-full h-auto mt-2  flex flex-wrap gap-4'>
                        <div className=" w-[100%] flex grow ">
                            <div className=" w-[calc(100%-180px)] lg:w-[calc(100%-240px)] flex flex-col">
                                <label className=" text-nav2 cursor-pointer" htmlFor="customer">Customer Name</label>
                                <select onChange={(e)=>setCustomerS(e.target.value)} className=" p-1 text-slate-500 shadow1 cursor-pointer border-none outline-none" name="customer" value={customerS} id="customer">
                                <option value="" >Select customer</option>
                                {
                                    customer.map((data, index)=>{
                                        return(
                                            <option key={index} value={data._id}>{data.name}</option>
                                        );
                                    })
                                }
                                </select>
                            </div>
                            <div className=" w-[180px] lg:w-[240px] h-8 mt-5 ml-1 flex">
                                <input onChange={(e)=> setSrcC(e.target.value)} className=' w-[90px] lg:w-[120px] shrink-0 pl-4 border-none outline-none shadow1 text-nav2 placeholder-nav2' type="text" name="search" id="search" value={srcC} placeholder="Search..." />
                                <div className=" w-full flex">
                                    <button onClick={getCustomerBySearch} className=" w-9 h-8 ml-2 flex items-center justify-center shadow3 text-nav2  shrink-0">
                                        <i className="fa-solid fa-magnifying-glass text-lg"></i>
                                    </button>
                                    <Link to='/addCustomer' className=" w-9 h-8 ml-2 flex items-center justify-center shadow3 text-nav2  shrink-0">
                                    <i className="fa-solid fa-plus text-xl"></i>
                                    </Link>
                                </div>
                            </div>                        
                        </div>
                    </div>
                    <div className=' w-full max-h-96 overflow-y-auto p-2 mt-4 shadow1 bg-blue-500'>
                    {
                        (cart.length > 0)?
                        <table className=" w-full">
                            <thead>
                                <tr className=" border-b border-slate-300 text-nav2">
                                    <th>Name</th>
                                    <th>Sale Price</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cart.map((data, index)=>{
                                        return(
                                            <tr key={index} className=" border-b border-slate-300 text-slate-800">
                                                <td className=" text-center">{data.name}</td>
                                                <td className=" text-center">{data.salePrice}৳</td>
                                                <td className=" text-center">
                                                {
                                                        // (data.serialNumberStatus === true)?
                                                        // <span>{data.quantity}</span>:
                                                    <div className=" w-[100px] my-4 shadow2 flex items-center justify-evenly">
                                                        <button onClick={()=>dispatch(REMOVE_QUANTITY_QU({id:data.id}))} className=" text-red-700"><i className="fa-solid fa-minus"></i></button>
                                                        <span>{data.NDquantity}</span>
                                                        <button onClick={()=>dispatch(ADD_QUANTITY_QU({id:data.id}))} className=" text-green-700"><i className="fa-solid fa-plus"></i></button>
                                                    </div>
                                                }
                                                    {/* <div className=" w-[100px] my-4 shadow2 flex items-center justify-evenly">
                                                        <button onClick={()=>dispatch(REMOVE_QUANTITY({id:data.id}))} className=" text-red-700"><i className="fa-solid fa-minus"></i></button>
                                                        <span>{data.quantity}</span>
                                                        <button onClick={()=>dispatch(ADD_QUANTITY({id:data.id}))} className=" text-green-700"><i className="fa-solid fa-plus"></i></button>
                                                    </div> */}
                                                </td>
                                                <td className=" text-center">{(data.salePrice * data.NDquantity) }৳</td>
                                                <td className=" pl-4">
                                                <button onClick={()=>{setCartInfo(data); setSerialNumber(data.serialNumber)}} className=' text-green-500'><i className="fa-solid fa-pen-to-square"></i></button>
                                                    <button onClick={()=> dispatch(REMOVE_FORM_CART(data.id))} className=' text-red-600 ml-1 '><i className="fa-solid fa-xmark text-xl"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>:
                        <h1 className=" w-full text-center text-red-800">Cart is empty.</h1>
                    }
                    </div>
                </div>
            </section>
            <section className=" hidden  w-full h-[80px] bg-slate-200 lg:flex items-center justify-start select-none">
                <div className=" w-max flex pl-4 gap-4 items-center justify-center">
                    <div className=" w-max flex flex-col gap-2">
                        <div>
                            <label className=" cursor-pointer text-nav2" htmlFor="discountType">Discount Type : </label>
                            <select onChange={(e)=>setDiscountType(e.target.value)} className=" px-3 text-slate-700 shadow1 cursor-pointer border-none outline-none" name="discountType" value={discountType} id="discountType">
                                <option value="%">%</option>
                                <option value="tk">tk</option>
                            </select>  
                        </div>
                        <div>
                            <label className=" cursor-pointer text-nav2" htmlFor="discount">Discount : </label>
                            <input onChange={(e)=>setDiscount(e.target.value)} className=" w-[100px] px-5 shadow1 border-none outline-none cursor-text" type="number" name="discount" value={discount} id="discount"></input>
                        </div>          
                    </div>
                    <div>
                        <label className=" cursor-pointer font-bold" htmlFor="paid">Vat/Tax: </label>
                        <input onChange={(e)=>setVat(e.target.value)} className=" w-[160px] shrink px-5 shadow1 border-none outline-none cursor-text" type="number" name="paid" value={vat} id="paid" ></input>
                    </div>
                    <div className=" text-xl">
                        <span className=" font-bold">Total Amount: </span>
                        <span>{totalPrice.toFixed(2)}৳</span>
                    </div>
                    {/* <div>
                        <label className=" cursor-pointer font-bold" htmlFor="paid">Paid: </label>
                        <input onChange={(e)=>setPaid(e.target.value)} className=" w-[160px] shrink px-5 shadow1 border-none outline-none cursor-text" type="number" name="paid" value={paid} id="paid" ></input>
                    </div> */}
                    {/* <div className=" text-xl">
                        <span className=" font-bold">Due: </span>
                        <span>{(totalPrice - paid).toFixed(2)}৳</span>
                    </div> */}
                    <div>
                        <button onClick={()=>{ dispatch(CANCEL_ORDER()); setPaid(0); setCustomerS("");setDiscount(0); setDiscountType("%"); setVat(0)}}className=" mr-2 p-1 px-2 bg-red-700 hover:bg-red-900 text-white rounded-md">Clear</button>
                        <button onClick={saveQuotation} disabled={buttonDisable} className=" mr-2 p-1 px-2 bg-yellow-600 hover:bg-yellow-700 text-white rounded-md">Quotation</button>
                        {/* <button onClick={save} className=" p-1 px-2 bg-nav2 hover:bg-nav2hover text-white rounded-md">Place Order</button> */}
                    </div>
                </div>
            </section>
            <div className=" lg:hidden w-full mt-20 text-lg text-center font-bold text-red-800">This feature is only available for large device!</div>
        </>
    )
}

export default Quotation;