import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Form1, Select, Option } from  '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddUser() {
  const [value, setValue] = useState({name:'', email:'', phone:'', password:'',  rPassword:'', role:'Admin', designation:""});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    if(value.name === '' || value.email === '' || value.phone === '' || value.password === '' || value.rPassword === ''  || value.role === '' || value.designation === ''){
      setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="All fields are required." />]);
    }else{

      if(value.password !== value.rPassword){
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Wrong Password"/>]);
      }else{

        fetch(`${url}/user`,{
          method:"POST",
          body: JSON.stringify(value),
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            navigate(-1);
          }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
          }
        });
      }
    }

}


  return (
    <Form1 title="Add User">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} type="text" name="name" placeholder="Enter user name:" value={value.name} label="User name:" />
        <Input1 onChange={set} type="email" name="email" placeholder="Enter user email:" value={value.email} label="User email:" />
        <Input1 onChange={set} name="phone" placeholder="Enter user phone:" value={value.phone} label="User phone number:" />
        <Select onChange={set} name="role" value={value.role} label="Select Role:" id="role">
          <Option value="Admin">Admin</Option>
          <Option value="Manager">Manager</Option>
        </Select>
        <Input1 onChange={set} type="text" name="designation" placeholder="Enter user designation:" value={value.designation} label="User designation:" />
        <Input1 onChange={set} type="password" name="password" placeholder="Enter user password:" value={value.password} label="Create password:" />
        <Input1 onChange={set} type="password" name="rPassword" placeholder="Repeat user password:" value={value.rPassword} label="Repeat password:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddUser;