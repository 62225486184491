import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

function CheckUser({status}){
    const { role } = useAuth();

    if(status === "manager"){
        return((role === "Manager")? <Outlet /> : <Navigate to='/' /> );
    }else if(status === "admin"){
        return((role === "Admin")? <Outlet /> : <Navigate to='/' /> );
    }else if(status === "both"){
        return((role === "Manager" || role === "Admin")? <Outlet /> : <Navigate to='/' /> );
    }

}

export default CheckUser;