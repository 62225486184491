import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Textarea,  Form1 } from  '../../components/Input';
import url from '../../url';

function AddAccessories() {
  const [value, setValue] = useState({name:'', description:'', price:0, quantity:0, date:''});
  const navigate = useNavigate();

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/accessories`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }
    });
  }


  return (
    <Form1 title="Add Accessories">
        <Input1 onChange={set} name="name" placeholder="Enter accessories name:" value={value.name} label="Accessories name:" id="name" />
        <Textarea onChange={set} name="description" placeholder="Enter accessories description:" value={value.description} label="Accessories description:" id="description" />
        <Input1 onChange={set} type="number" name="price" placeholder="Enter accessories price:" value={value.price} label="Accessories price:" id="price"/>
        <Input1 onChange={set} type="number" name="quantity" placeholder="Enter accessories quantity:" value={value.quantity} label="Accessories quantity:" id="quantity"/>
        <Input1 onChange={set} type="date" name="date" placeholder="Enter accessories date:" value={value.date} label="Accessories buying date:" id="date"/>
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddAccessories;