// const url = 'http://localhost:8000'
// const invUrl = "http://localhost:3000";

 // const url = 'https://posapi.porishebabd.com';
//  const invUrl = "https://pos.easysheba.com";

const url = "https://pharmacyapi.easysheba.com"
const invUrl = "https://pharmacy.easysheba.com"

export default url;

export{ invUrl };