import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Option, Select, Textarea, Form1 } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddExpense() {
  const [value, setValue] = useState({name:'', description:'', amount:0, expenseCategoryId:'',by:'' });
  const [category, setCategory] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();


  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }


  function send(){

      fetch(`${url}/expense`,{
        method:"POST",
          body:  JSON.stringify(value),
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });

    }

  useEffect(()=>{

    fetch(`${url}/expenseCategory?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
          setCategory(data.result.data)
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[]);


  return (
    <Form1 title="Add Expense">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter expense name:" value={value.name} label="Expense name:" />
        <Textarea onChange={set} name="description" placeholder="Enter expense details:" value={value.description} label="Expense details:" />
        <Input1 onChange={set} type="number" name="amount" placeholder="Enter expense amount:" value={value.amount} label="Expense amount:" />
        <Select onChange={set}  name="expenseCategoryId" value={value.expenseCategoryId}  id="input4"  label="Select expense category :">
          <Option value="" disable={true}>Select a category</Option>
          {
              category.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Input1 onChange={set} name="by" placeholder="Enter expense by:" value={value.by} label="Expense by:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddExpense;