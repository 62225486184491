import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Due, Invoice } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function AllPurchaseDue() {

const [purchase, setPurchase] = useState({data:[],next:{},previous:{}});
// const [total, setTotal] = useState({ payable:0, due:0, paid:0});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);


useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/purchase/allPayDue?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setPurchase(data.result);

                // let payable = 0, due = 0, paid = 0;
                // data.result.data.map((data)=>{
                //     payable = payable + data.purchase.totalPrice;
                //     due = due + data.purchase.due;
                //     paid = paid + data.paidNow;
                //     return(0);
                // });
        
                // setTotal({ payable, due, paid});
            }
        });
    }

    return () => setPurchase({data:[],next:{},previous:{}});

},[page,src, limit]);


function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="All Purchase Due" rowNames={["#","Supplier Name","Supplier email","Supplier phone","Payable", "Paid  Now", "Due Now","Date","Actions"]} srcVal={src} srcFunc={search} data={purchase} width='w-full'>
    {
        purchase.data.map(({_id, purchase : dueData, paidNow, due,  date}, index)=>{
            const d = new Date(date);
            return(
                <Tr key={index}>
                    <Td>{((purchase.previous.page*purchase.previous.limit)+1)+index}</Td>
                    <Td>{dueData.supplierName}</Td>
                    <Td>{dueData.supplierEmail}</Td>
                    <Td>{dueData.supplierPhone}</Td>
                    <Td>{dueData.totalPrice }</Td>
                    <Td>{paidNow}</Td>
                    <Td>{dueData.due}</Td>
                    <Td>{d.toLocaleString()}</Td>
                    <Td>
                        {/* <View to={"/purchase/view/"+_id} /> */}
                        {
                            (dueData.due > 0)?<Due to={"/purchase/due/"+dueData._id} />:<></>
                        }
                        <Invoice to={"/purchase/due/invoice/"+_id} />
                    </Td>
                </Tr>
            );
        })
    }

    {/* <tr className=" bg-slate-400 text-black">
        <td></td>
        <td></td>
        <td></td>
        <td className="  font-bold">Total</td>
        <td className=" border-x border-black font-bold text-center">{total.payable}</td>
        <td className=" border-x border-black font-bold text-center">{total.paid}</td>
        <td className=" border-x border-black font-bold text-center">{total.due}</td>
        <td></td>
        <td></td>
    </tr> */}
        
</Table>
}</>)
}

export default AllPurchaseDue;