const initialState = 3 ;

function paginate(state = initialState, action){
    switch(action.type){

        case 'ADD_LIMIT' : 
        state = action.data;
        return state;
       
        default : return state;

    }

}

export default paginate;