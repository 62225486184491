import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Option, Select, Textarea, Form1, CheckBox } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddProduct() {
  const [value, setValue] = useState({name:'', description:'', purchasePrice:0, salePrice:0, categoryId:'', vat:0, warranty:true, warrantyDays:0, expire:false, expireIn: "", serialNumber:true, image:{}});
  const [category, setCategory] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();


  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }


  function getImage(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }



  function send(){
    
    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('salePrice',value.salePrice);
    formData.append('purchasePrice',value.purchasePrice);
    // formData.append('vat',value.vat);
    formData.append('warranty',value.warranty);
    formData.append('warrantyDays',value.warrantyDays);
    formData.append('expire',value.expire);
    formData.append('expireIn',value.expireIn);
    formData.append('serialNumber',value.serialNumber);
    formData.append('categoryId',value.categoryId);
    formData.append('photo',value.image);


      fetch(`${url}/product`,{
        method:"POST",
          body:  formData,
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });

    }

  useEffect(()=>{

    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
          setCategory(data.result.data)
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[]);


  return (
    <Form1 title="Add Product">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter product name:" value={value.name} label="Product name:" />
        <Textarea onChange={set} name="description" placeholder="Enter product description:" value={value.description} label="Product description:" />
        <Input1 onChange={set} type="number" name="purchasePrice" placeholder="Enter product purchase price:" value={value.purchasePrice} label="Product purchase price:" />
        <Input1 onChange={set} type="number" name="salePrice" placeholder="Enter product sale price:" value={value.salePrice} label="Product sale price:" />
        {/* <Input1 onChange={set} type="number" name="vat" placeholder="Enter product vat/tax(%):" value={value.vat} label="Product vat/tax (%):" /> */}
        <Select onChange={set}  name="categoryId" value={value.categoryId}  id="input4"  label="Select category :">
          <Option value="" disable={true}>Select a category</Option>
          {
              category.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Input1 onChange={(e)=>getImage(e)} type="file" name="image" label="Upload an image." />
        <CheckBox onChange={(e)=>setValue({...value, "warranty":e.target.checked})} name="warranty" label="Warranty :" id="warranty" value={value.warranty} checked={value.warranty} />
        {
          (value.warranty === true)?
          <Input1 onChange={set} type="number" name="warrantyDays" placeholder="Enter warranty days:" value={value.warrantyDays} label="Warranty days:" />:<></>
        }

        <CheckBox onChange={(e)=>setValue({...value, "expire":e.target.checked})} name="expire" label="Expire :" id="expire" value={value.expire} checked={value.expire} />
        {
          (value.expire === true)?
          <Input1 onChange={set} type="date" name="expireIn" placeholder="Enter expire date:" value={value.expireIn} label="Expire date:" />:<></>
        }



        <CheckBox onChange={(e)=>setValue({...value, "serialNumber":e.target.checked})} name="serialNumber" label="Serial Number :" id="serialNumber" value={value.serialNumber} checked={value.serialNumber} />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddProduct;