import React from 'react';
import Layout from '../components/Layout';
import { NavLInkHome } from '../components/NavLink';
import AllTimeExpenseG from './chart/AllTimeExpenseG';
import AllTimeSaleG from './chart/AllTimeSaleG';
import DaySale from './chart/DaySale';
import MothSale from './chart/MothSale';
import ThisMonthExpenseG from './chart/ThisMonthExpenseG';
import ThisMonthSaleG from './chart/ThisMonthSaleG ';
import ThisWeekExpenseG from './chart/ThisWeekExpenseG';
import ThisWeekSaleG from './chart/ThisWeekSaleG';
import ThisYearExpenseG from './chart/ThisYearExpenseG';
import ThisYearSaleG from './chart/ThisYearSaleG';
import TodayExpenseG from './chart/TodayExpenseG';
import TodaySale from './chart/TodaySale';
import YearSale from './chart/YearSale';

function Home(){


    return (
        <Layout nav={<NavLInkHome />} >
            {/* <div className=' w-full h-full bg-slate-700'></div> */}
            <DaySale />
            <MothSale />
            <YearSale />
            <div className=" flex flex-wrap items-center justify-center">
                <TodaySale />
                <ThisWeekSaleG />
                <ThisMonthSaleG />
                <ThisYearSaleG />
                <AllTimeSaleG />
            </div>
            <div className=" flex flex-wrap items-center justify-center">
                <TodayExpenseG />
                <ThisWeekExpenseG />
                <ThisMonthExpenseG />
                <ThisYearExpenseG />
                <AllTimeExpenseG />
            </div>
            <div className=' w-full p-6 mt-10 bg-slate-800 text-white text-center'>
                <span>Copyright @{new Date().getFullYear()} | EasySheba Platform Limited | All Rights Reserved</span>
            </div>
        </Layout>
    )
}

export default Home;