import React from 'react';
import useAuth from '../hooks/useAuth';
import { Link1 } from './Button';

function NavLInkHome(){
    const { role } = useAuth();
    return(
        <>
        {
            (role === "Admin")?
            <>
                <Link1 to='/sale' >
                    <i className="fa-solid fa-bag-shopping text-xl"> </i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Sale</span>
                </Link1>
                <Link1  to='/purchase' >
                    <i className="fa-solid fa-cart-shopping text-xl"></i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Purchase</span>
                </Link1>
                <Link1 to='/quotation/create' >
                    <i className="fa-solid fa-file-invoice-dollar text-xl"></i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Quotations</span>
                </Link1>
                {/* <Link1 to='/Counter' > 
                    <i className="fa-solid fa-share text-xl rotate-[180deg]"></i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Customer Return</span>
                </Link1> */}
            </>:<></>
        }

        {
            (role === "Manager")?
            <>
                <Link1 to='/sale' >
                    <i className="fa-solid fa-bag-shopping text-xl"> </i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Sale</span>
                </Link1>
                <Link1  to='/purchase' >
                    <i className="fa-solid fa-cart-shopping text-xl"></i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Purchase</span>
                </Link1>
                {/* <Link1 to='/Counter' > 
                    <i className="fa-solid fa-cash-register text-xs md:text-xl"></i> 
                    <span className=' text-xs md:text-xl'> Counter</span>
                </Link1> */}
            </>:<></>
        }
        
    </>
    );
}

export { NavLInkHome };