import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1, Input1 } from '../../components/Input';
import url from '../../url';
import Loader from '../../components/Loader';


function PayDue(){
    const { id } = useParams();
    const [data, setData] = useState({});
    const [due, setDue] = useState(0);
    const [loader, setLoader] = useState(true);

    useEffect(()=>{
        setLoader(true);
        fetch(`${url}/purchase/${id}`, { method:'GET', mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            setData(data.data[0]);
            setLoader(false);
        });
    },[id]);

    function send(){
        fetch(`${url}/purchase/payDue/${id}`, { method:'PUT', body:JSON.stringify({paidNow:due, paid:data.paid, due:data.due}), mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            const wind = window.open("/purchase/due/invoice/"+data.id);
            setTimeout(() => {
                wind.close();
            }, 2000);

            fetch(`${url}/purchase/${id}`, { method:'GET', mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
                setData(data.data[0]);
                setLoader(false);
            });
            setDue(0);
        });
    }

    return(
        <div>
            <Form1>
                <div className=" text-white flex gap-4 flex-wrap">
                {
                    loader?<div className=' absolute top-10 w-full flex justify-center mt-5 '><Loader /></div>:<></>
                }
                    <div>
                        <span className=" font-bold">Total Payable: </span><span>{data.totalPrice}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Total Paid: </span><span>{data.paid}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Total Due: </span><span>{data.due}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Receivable Now: </span><span>{(data.due - due).toFixed(2)}</span>
                    </div>
                </div>
                <Input1 onChange={(e)=>setDue(e.target.value)} type="number" name="due" value={due} label="Pay Due:" id="due" />
                <Button1 click={send} name="Save" />
            </Form1>
        </div>
    )
}

export default PayDue;