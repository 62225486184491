import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import url from '../../url';

const COLORS = ['#005df4', '#007f10', '#ff0000', '#e500c6'];


function ThisMonthSaleG (){

  const [data, setData] = useState([]);
  const [sale, setSale] = useState([]);

  useEffect(()=>{
    fetch(`${url}/report/thisMonthSaleG `,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
      setData([{name:"Total Receivable", data:data.data[0].totalReceivable},{name:"Total Received", data:data.data[0].totalReceived},{name:"Total Due", data:data.data[0].totalDue},{name:"Total Profit", data:data.data[0].totalProfit},]);
      setSale([{name:"Total Sale", data:data.data[0].totalSale},])
    });
  },[]);


    return (
    <div className=" w-[800px]">
    <h1 className='py-6 md:-mb-8 mt-10 text-center text-xl font-bold text-nav2'>This Month Sale </h1>
    <div className=' w-full h-[350px] md:h-[250px] flex flex-col md:flex-row'>
      <ResponsiveContainer >
        <PieChart >
            
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={true}
            label
            outerRadius={50}
            fill="#8884d8"
            dataKey="data"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
            <Tooltip />
        </PieChart>

      </ResponsiveContainer>

      <ResponsiveContainer >
        <PieChart >
            
          <Pie
            data={sale}
            cx="50%"
            cy="40%"
            labelLine={true}
            label
            outerRadius={50}
            fill="#ff7200"
            dataKey="data"
            
          >
            {sale.map((entry, index) => (
              <Cell key={`cell-${index}`}  />
            ))}
          </Pie>
            <Tooltip />
        </PieChart>

      </ResponsiveContainer>

      </div>

      <div className=" w-full -mt-5  md:-mt-10 flex flex-col md:flex-row gap-2 items-center justify-center">
          <div className=" mt-2 flex items-center justify-center">
              <div className=' w-6 h-6 bg-[#005df4] rounded-full'></div>
              <span className=" pl-2 text-[#005df4]">Total Receivable(৳)</span>
          </div>
          <div className=" mt-2 flex items-center justify-center">
              <div className=' w-6 h-6 bg-[#007f10] rounded-full'></div>
              <span className=" pl-2 text-[#007f10]">Total Received(৳)</span>
          </div>
          <div className=" mt-2 flex items-center justify-center">
              <div className=' w-6 h-6 bg-[#ff0000] rounded-full'></div>
              <span className=" pl-2 text-[#ff0000]">Total Due(৳)</span>
          </div>
          <div className=" mt-2 flex items-center justify-center">
              <div className=' w-6 h-6 bg-[#e500c6] rounded-full'></div>
              <span className=" pl-2 text-[#e500c6]">Total Profit(৳)</span>
          </div>
          <div className=" mt-2 flex items-center justify-center">
              <div className=' w-6 h-6 bg-[#ff7200] rounded-full'></div>
              <span className=" pl-2 text-[#ff7200]"> Sale's Quantity</span>
          </div>
        </div>
      </div>
    );
  }

  export default ThisMonthSaleG ;