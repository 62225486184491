import React from 'react';
import Barcode from 'react-barcode';
import { useState } from 'react';
import { useEffect } from 'react';


function ProductBarcode({addSerial}){

    const [data, setData] = useState([]);


    useEffect(()=>{
        setData([...data, <Barcode key={Date.now() + Math.round(Math.random()*100)} width={1} value={String(Date.now() + Math.round(Math.random()*100))} />]);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function add(){
        setData([...data, <Barcode key={Date.now() + Math.round(Math.random()*100)} width={1} value={String(Date.now() + Math.round(Math.random()*100))} />]);
    }

    function remove(){
        let n = [];
        n = data;
        n.pop();
        setData([...n]);
    }


    return(
        <>
        <div className=' w-full h-auto flex flex-col items-center justify-center'>
            <div className=" printHide flex items-center justify-center gap-5 my-5">
                {/* <input className=' w-16 rounded-md border-2 border-orange-600 ' onChange={(e)=>setQuantity(e.target.value)} type="Number" name="quantity" value={quantity} id="quantity" label="Quantity:" /> */}
                <button className='px-1 rounded-md bg-green-600 text-white'  onClick={add}><i className="fa-solid fa-plus text-2xl"></i></button>
                <button className='px-1 rounded-md bg-red-600 text-white' onClick={remove}><i className="fa-solid fa-minus text-2xl"></i></button>
                <button onClick={()=> window.print()} className=" px-1 rounded-md text-2xl bg-orange-600 text-white"><i className="fa-solid fa-print"></i></button>
            </div>
            <div className=' w-full h-auto flex flex-wrap justify-center'>
                {
                    data.map((data)=>{
                        return(data)
                    })
                }
            </div>
        </div>
        </>
    )
}

export default ProductBarcode;