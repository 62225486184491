import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import url from '../../url';
import Loader from '../../components/Loader';
import { Alert2, AlertContainer } from '../../components/Alert';

function ViewSale(){

    const [sale, setSale] = useState({_id:'', cart:[], receivable:0, received:0, due:0, customerName:"", customerEmail:"", customerPhone:"", customerAddress:"", totalPrice: 0, discount:0, discountType:'', quantity:0, date:''});
    const [loader, setLoader] = useState(true);
    const [alert, setAlert] = useState([]);
    const {id} = useParams();

    useEffect(()=>{
        setLoader(true)
        fetch(`${url}/sale/return/${id}`,{
          method:"GET",
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            setLoader(false)
            if(data.status === true){
                setSale(data.data[0]);
            }else{
              setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    // eslint-disable-next-line 
      },[id]);

    const d = new Date(sale.date);
    const dat = d.toLocaleString();

    let total = 0;

    return(
            <div className=" bg-white drop-shadow-md w-11/12 mx-auto mt-4 rounded-sm  h-max p-4"> 
            <div className= " fixed top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>          
            {
                loader?<div className=' absolute w-full flex justify-center mt-5 '><Loader /></div>:<></>
            }
                <div className=" rounded-md m-1 flex flex-col md:justify-evenly md:flex-row text-xs sm:text-sm md:text-base pb-20">
                    <table className=" w-full table-auto border-collapse border-slate-500 mt-10">
                        <thead>
                            <tr className=" text-left border-slate-500 border-b">
                                <th>Item Name</th>
                                <th>Price</th>
                                <th>Vat/Tax</th>
                                <th>Quantity</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sale.cart.map((data,index)=>{
                                    total = total + (data.salePrice * data.quantity);
                                    return(
                                    <tr key={index} className=" border-slate-500 border-b">
                                                             <td className=" flex flex-col">
                                        <span>{data.name}</span>
                                        {
                                            data.serialNumber.map((data, index)=>{
                                                return(<span key={index}> {data},</span>);
                                            })
                                        }
                                    </td>
                                    <td>{data.salePrice}  ৳</td>
                                    <td>{data.vat}%</td>
                                    <td>{data.quantity}</td>
                                    <td>{((data.salePrice * data.quantity) )}  ৳</td>
                                </tr>)
                                })
                            }

                            <tr >
                                <td></td>
                                <td></td>
                                <th ></th>
                                <td></td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Total :</th>
                                <td className="  ">{total}  ৳</td>
                            </tr>

                            <tr className=" border-b-[24px] border-transparent "></tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Vat/Tax :</th>
                                <td className=" ">{sale.vat} %</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Discount :</th>
                                <td className=" ">{sale.discount} {sale.discountType}</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Returnable :</th>
                                <td className="  ">{sale.totalPrice}  ৳</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <th className=" border-slate-500 border-b text-left">Return :</th>
                                <td className=" border-slate-500 border-b  ">{sale.paid}  ৳</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Due :</th>
                                <td className=" ">{sale.due}  ৳</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className=" text-xs sm:text-sm md:text-base relative -mt-10">
                    <b> Date : </b>{dat}
                </div>
            </div>
    );
}

export default ViewSale;