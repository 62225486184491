import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../components/Button';
import { Input1, Form1 } from '../components/Input';
import url from '../url';
import { Alert2, AlertContainer } from '../components/Alert';
import useAuth from '../hooks/useAuth';

function Setting() {
  const [value, setValue] = useState({name:'', email:'', phone:'', image:{}});
  const [ img, setImg] = useState("");
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();
  const {id} = useAuth();
   

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function getImage(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){

    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('email',value.email);
    formData.append('phone',value.phone);
    formData.append('photo',value.image);

    fetch(`${url}/user/${id}`,{
        method:"PUT",
        body:  formData,
        credentials: 'include'
      }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate('/profile');
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });
    }

  useEffect(()=>{

    fetch(`${url}/user/${id}`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data.name, email: data.data.email,  phone:data.data.phone});
          setImg(data.data.img);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[id]);



  return (
    <Form1 title="Edit User">
          <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter user name:" value={value.name} label="User name:" />
        <Input1 type="email" name="email" placeholder="Enter user email:" value={value.email} label="User email:" />
        <Input1 onChange={set} type="text" name="phone" placeholder="Enter user phone:" value={value.phone} label="User phone:" />

        <div className=' p-2 mt-8 rounded-sm'><img className=' w-20' src={img} alt="" /></div>
        <Input1 onChange={(e)=>getImage(e)} type="file" name="image" label="Upload an image." />

        <Button1 click={send} name="Save" />
    </Form1>

  )
}


export default Setting;